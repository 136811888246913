<template>
  <div class="alert-container" @keypress.enter="goHome()">
    <img class="logo" src="@/assets/svg/alert-error.svg" />
    <div style="height: 0px" class="form-group"></div>
    <div class="title">
      <span>Không thành công</span>
    </div>

    <div class="form-group message">
      Link xác thực email đã hết hiệu lực. Vui lòng xác thực lại email !
    </div>
    <div style="height: 0px" class="form-group"></div>
    <div class="form-group">
      <button class="btn btn-primary" @click="goHome">Về trang chủ</button>
    </div>
        <div style="min-height: 30px"></div>

  </div>
</template>
<script>
export default {
  methods:{
     goHome() {
      this.$router.push('/login')
    },
  }
}
</script>

<style lang="scss" scoped>
.row {
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
  .btn {
    width: 48%;
  }
  .btn-primary {
    color: #2b2b2b;
    background: #f6f8fb;
    img {
      margin-right: 10px;
    }
  }
}
.alert-container {
  text-align: center;
  margin: auto;
  .title {
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 10px;
    display: flex;

    .action {
      display: flex;
      width: 30px;
    }

    span {
      width: 100%;
      flex: 1;
    }
  }

  .logo {
    width: 80px;
  }

  .message {
    text-align: center;
    color: #626262;
    font-size: 20px;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 500px) {
  .alert-container {
    width: 100%;
    margin: 0;
  }
}
</style>